<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить администратора</h1>
      </div>
      <!--<div class="page-header__ell" >-->
      <!--  <b-form-checkbox v-model="checked" name="check-button" size="lg" switch>-->
      <!--    Включить / отключить-->
      <!--  </b-form-checkbox>-->
      <!--</div>-->
    </div>
    <ManagerEditForm ref="userAddForm" />
    <form class="form">
      <div class="form__btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.MANAGER_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import ManagerEditForm from "@component/Form/ManagerEditForm";

export default {
  name: "ManagerAdd",
  components: {
    ManagerEditForm,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {

    async save() {

      let formData = this.$refs.userAddForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);
      sendData.clubs = JSON.stringify( sendData.clubList.map((item) => item.id) );
      delete sendData.clubList;

      if(sendData.access === 'system') {
        sendData.is_superuser = true;
      } else {
        sendData.is_superuser = false;
      }
      delete sendData.access;

      delete sendData.avatar;

      // { username, /*mobile,*/ first_name, last_name, middle_name, about }
      RequestManager.Manager.addManager(sendData ).then( (res) => {
        // this.$toasts.push({
        //   message: 'Администратор успешно добавлен',
        //   duration: 4000,
        // });
        this.$dialogs.alert('Пароль для входа: ' + res.password , { title: 'Администратор успешно добавлен'} ).then(() => {
          this.$router.push({name: this.$routeName.MANAGER_LIST });
        });

      });

    }
  }
};
</script>

<style lang="scss" scoped>

</style>
